/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/prismjs@1.29.0/prism.min.js
 * - /npm/prismjs@1.29.0/plugins/line-numbers/prism-line-numbers.min.js
 * - /npm/prismjs@1.29.0/components/prism-python.min.js
 * - /npm/prismjs@1.29.0/components/prism-javascript.min.js
 * - /npm/prismjs@1.29.0/components/prism-sql.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
